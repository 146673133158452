import gql from 'graphql-tag';

// eslint-disable-next-line import/prefer-default-export
export const shopVendorVariantFields = gql`
  fragment shopProductInfo on VendorVariantModel {
    id
    sku
    title
    isOnSale
    image {
      uri
    }
    images {
      uri
    }
    price: currentPrice
    retailPrice {
      id
      price
    }
    vendor {
      id
      name
    }
    availability
    productUrl
    productGroupUrl
    groupCount
    designAward
    productDetailsUrl
  }
`;
