import { ParsedUrlQuery } from 'querystring';
import { IMerchGroup } from '@models/MerchGroup/i-merch-group';
import { getVendors, IVendorGroup } from '@components/shared/shop/model/VendorGroup';
import { isArray } from 'lodash';
import formatPrice from '@utils/shared/format/formatPrice';
import { ApolloClient } from '@apollo/client';
import PRODUCT_SEARCH_QUERY from '@components/pages/ShopV3/graphql/product-search.graphql';
import { getApollo } from '@lib/apollo/apolloClient';
import { IMerchGroupCategory } from '@components/shared/shop/model/MerchGroupCategory';
import { isValidOption } from './sortOptions';
import {
  IFacet,
  IFacetValue,
  ISearchCriteria,
  parsePriceFacetValue,
  SHOP_PAGE_SIZE
} from '../model/SearchResults';

export const SHOP_SEARCH_URL = '/shop/search';

/**
 * Returns the apollo client configured for the needs of Shop search.
 */
export function getApolloForSearch() {
  return getApollo({
    /*
       DO NOT RE-ENABLE THIS CACHE OR BAD THINGS™ WILL HAPPEN.

       The ProductSearchFacetValue objects returned by graph search results
       don't have an ID field that distinguishes them based on the parent
       facet they belong to.  With the cache enabled, there is the potential
       for cross contamination between unrelated filter objects (e.g. between
       Vendors and Attribute Family based filters)

       todo: Update the 'id' field for 'ProductSearchFacetValue' objects in
       graph-product so that we can re-enable the cache here.
     */
    cacheEnabled: false,
  });
}

export function createSearchCriteria({
  query,
  selectedMerchGroup,
  selectedMerchGroupCategory,
  selectedVendorGroup,
}: {
  query: ParsedUrlQuery;
  selectedMerchGroup?: IMerchGroup | null;
  selectedMerchGroupCategory?: IMerchGroupCategory | null;
  selectedVendorGroup?: IVendorGroup | null;
}) {
  const {
    text, page, priceMin, priceMax, category, vendor, sort, onlyShopAssortment, attributeFamilies
  } = query;

  const searchCriteria: ISearchCriteria = {
    pageSize: SHOP_PAGE_SIZE,
    page: Number(cleanParam(page, '1')),
    facets: []
  };

  if (text) {
    searchCriteria.query = cleanParam(text);
  }

  if (selectedMerchGroup) {
    searchCriteria.merchGroupIds = [Number(selectedMerchGroup.id)];
  }

  if (selectedVendorGroup) {
    searchCriteria.vendorIds = getVendors(selectedVendorGroup);
  } else if (vendor) {
    searchCriteria.vendorIds = [Number(vendor)];
  }

  if (onlyShopAssortment) {
    searchCriteria.onlyShopAssortment = true;
  }

  if (category) {
    searchCriteria.taxonomyIds = [Number(category)];
  }

  if (attributeFamilies) {
    searchCriteria.attributeFamilies =
      isArray(attributeFamilies) ? attributeFamilies.map(Number) : Number(attributeFamilies);
  }

  if (selectedMerchGroupCategory) {
    searchCriteria.merchGroupIds = (selectedMerchGroupCategory?.children || []).map(
      (merchGroup) => Number(merchGroup.id)
    );
  }

  searchCriteria.facets!.push('PRICE');
  searchCriteria.facets!.push('VENDOR');
  searchCriteria.facets!.push('ATTRIBUTE_FAMILY');

  if (sort) {
    const sortOption = sort as string;

    if (isValidOption(sortOption)) {
      searchCriteria.sort = sortOption.toUpperCase();
    }
  }

  const cleanPriceMin = cleanNumberParam(priceMin);
  if (cleanPriceMin) {
    searchCriteria.priceMin = cleanPriceMin;
  }
  const cleanPriceMax = cleanNumberParam(priceMax);
  if (cleanPriceMax) {
    searchCriteria.priceMax = cleanPriceMax;
  }

  return searchCriteria;
}

export async function productSearch(
  apolloClient: ApolloClient<any>,
  searchCriteria: ISearchCriteria
) {
  const results = await apolloClient.query({
    query: PRODUCT_SEARCH_QUERY,
    variables: {
      criteria: searchCriteria
    }
  });
  return results;
}

export function parseFacets(facetData: any[]) {
  const facets: Record<string, IFacet> = {};
  facetData
    .map((facet) => parseFacet(facet))
    .forEach((facet) => { facets[facet.name] = facet; });
  return facets;
}

function formatPriceFacet(value: IFacetValue) {
  const [lower, upper] = parsePriceFacetValue(value)
    .map((v) => {
      return v
        ? v.replace('.01', '.00')
        : null;
    });

  if (!lower) {
    return `Under ${formatPrice(upper!)}`;
  } else if (!upper) {
    return `${formatPrice(lower)} & Above`;
  } else {
    return `${formatPrice(lower)} to ${formatPrice(upper)}`;
  }
}

function parseFacet(facet: any): IFacet {
  return {
    name: facet.name,
    values: facet.values
      .filter(({ count }: any) => count > 0)
      .map(({ id, value, count }: any) => {
        const facetValue = { id, count, value };
        if (facet.name === 'price') {
          facetValue.value = formatPriceFacet(facetValue);
        }
        return facetValue;
      })
  };
}

function cleanParam(
  value: string | string[] | null | undefined,
  defaultValue: string = ''
): string {
  return (value && ((typeof value === 'string') ? value : value[0]).trim()) || defaultValue;
}

function cleanNumberParam(
  value: string | string[] | null | undefined,
  defaultValue: number = 0
): number {
  const cleanStr = cleanParam(value);
  return cleanStr ? Number(cleanStr) : defaultValue;
}
